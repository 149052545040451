import consumer from "./consumer"

document.addEventListener('DOMContentLoaded', () => {
    const chatInput = document.getElementById('chat-input');
    const sendButton = document.getElementById('send-button');

    const tasksChannel = consumer.subscriptions.create("TasksChannel", {
        connected() {
            console.log("Connected to TasksChannel");
        },
        disconnected() {
            console.log("Disconnected from TasksChannel");
        },
        received(data) {
            // Handle task updates
            if (data) {
                updateTasksList();
                updateCompletedTaskList();
            }
        },
        speak(message) {
            this.perform('speak', { message: message });
        }
    });

    if (sendButton){
        sendButton.addEventListener('click', () => {
            tasksChannel.speak(chatInput.value);
            chatInput.value = '';
        });
    }

    window.tasksChannel = tasksChannel; // Make the tasksChannel available globally for testing

    function updateTasksList() {
        // Pass the current URL params to get_task_list
        let currentParams = new URLSearchParams(window.location.search);
        // assure it does not have the completed_refresh param
        currentParams.delete('completed_refresh');
        let url = `/admin/task_management2/get_task_list?${currentParams.toString()}`;
        loadContentDynamically('tasks-table', url);
    }

    function updateCompletedTaskList() {
        // Pass the current URL params and add the completed_refresh=true param
        let currentParams = new URLSearchParams(window.location.search);
        currentParams.set('completed_refresh', 'true');
        let url = `/admin/task_management2/get_task_list?${currentParams.toString()}`;
        loadContentDynamically('completed-tasks', url);
    }

    function loadContentDynamically(selector, url, callback = null){
        let parent = document.getElementById(selector);
        if (parent) {
            fetch(url)
                .then(response => response.text())
                .then(html => {
                    parent.innerHTML = html;
                    if (callback) { callback(); }
                })
                .then(() => {
                    setSortableValues();
                })
                .catch(error => {
                    console.error('Error loading the dynamic content:', error);
                    parent.innerHTML = '<li><span>Error loading content</span></li>';
                });
        }
    }

    function setSortableValues() {
        $(".sortable").sortable({
            axis: "y", // Simplified for demonstration; adapt as necessary
            items: ".sortable-item",
            handle: ".sortable-handle",
            update: function(event, ui) {
                // Assuming you have a way to serialize and save the new order
                var sortedData = $(this).sortable('serialize');
                $.post($(this).data("sortable-url"), sortedData);
            }
        }).disableSelection();
    }
});
